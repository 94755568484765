import React from "react"


const Staff = ({heading, body, image}) => (
<div className="staffCard">
  <div className="staffText"><h2>{heading}</h2>
    <p>{body}</p>
  </div>
   <div className="staffPhoto">
     <img src={image} />
   </div> 
  </div>
)

export default Staff
