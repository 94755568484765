import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Staff from "../../components/staff"
import akaash from "../../images/akaash.jpg"
import jon from "../../images/jon.jpg"
import tam from "../../images/tamaryn.jpg"
import irene from "../../images/irene.jpg"

const OurTeam = () => (
  <Layout linkColor="#5388ff" language="fr">
    <SEO title="Our team" />
    <div className="paddedBody">
    <h1>Notre equipe</h1>
    <div className="staffList">
    <Staff heading="Akaash Mukherjee  - Directeur et Partenaire"
    body="Akaash est passionné par les idées qui conduisent à l’innovation. Après avoir obtenu son baccalauréat en génie de l’université McGill, il s’est joint à Jonar au début de la conception de ParagonERP, le nouveau produit ERP de Jonar. Il a porté plusieurs chapeaux multidisciplinaires, agissant en tant que directeur technique, architecte logiciel, programmeur et chef d’équipe. Aujourd’hui, il travaille toujours avec Jonar mais dans un nouveau rôle : en tant que directeur de l’incubateur d’innovation, Jonar Labs. Sa mission est de façonner le paysage technologique de demain."
    image={akaash} />
    <Staff heading="Jon Ruby - Partenaire Fondateur"
    body="Jon Ruby affiche une rare intersection d’expertise technologique et de sens des affaires, appliquant de manière créative et avant-gardiste de nouvelles technologies aux défis du monde réel. Passionné par l’expérience utilisateur et le design, Jon s’efforce à enrichir la vie quotidienne des utilisateurs des technologies pour entreprises en concevant des logiciels qui sont puissants et plaisants à utiliser. À la fin des années 1990, Jon a co-fondé l’entreprise Control-F1, un leader du secteur eSupport. Il a ensuite dirigé des petites entreprises en conseil aux banques d’investissement, en fonds de placement privé de ressources naturelles et en dispositifs médicaux. En tant que CEO de Jonar, Jon et son équipe ont conçu, construit et lancé un nouveau système ERP appelé ParagonERP qui vise à révolutionner l'industrie."
    image={jon} />
    <Staff heading="Tamaryn de Kock - Partenaire"
    body="Tamaryn veut décortiquer vos pensées. Sa curiosité naturelle l’a poussée à poursuivre des études en sciences psychologiques à l’université McGill. Après avoir obtenu son diplôme, elle a travaillé chez Jonar en tant que stagiaire en marketing. Au cours de cette période, elle a découvert sa passion pour l’analyse du comportement des consommateurs. Aujourd’hui, Tamaryn poursuit cette passion en tant que gestionnaire des ressources humaines chez Jonar et partenaire chez Jonar Labs."
    image={tam} />
    <Staff heading="Irene de Gooyer-Collins - Partenaire"
    body="Irene est une femme d’affaires chevronnée, possédant une vaste expérience en produits de consommation, en distribution en gros, en fabrication et en logiciels. Elle est actuellement directrice financière chez Wonderment Ventures, qui fournit des capitaux et des expertises à des groupes de jeunes entreprises sélectionnées dans divers secteurs de la consommation. Elle a également rejoint le conseil d’administration des York Angel Investors en juin 2016 et est activement impliquée dans la communauté entrepreneuriale. Irene est aussi directrice du développement des affaires chez Jonar et partenaire chez Jonar Labs."
    image={irene} />
    </div>
  </div>
  </Layout>
)

export default OurTeam
